var $ = require('jquery');
var imagesLoaded = require('imagesloaded');
var TweenLite = require('gsap');
var scrollTo = require('../../node_modules/gsap/src/uncompressed/plugins/ScrollToPlugin.js');
var slick = require('../../node_modules/slick-carousel/slick/slick.min.js');

var WOW = require('wowjs');

new WOW.WOW().init();

// var Parsley = require('parsleyjs');
// var Hammer = require('hammerjs');

window.app = {
	W: $(window).width(),
	H: $(window).height(),

	scrollTop: $(window).scrollTop(),
	isMobileNavOpen: false,
	isHome: $('body.home').length > 0,

	sliderInstance: {},

	init: function() {

		var _this = this;

		_this.setupEvents();
		_this.preload([]);

		_this.initWOW();

		$('.slider').slick({
			autoplay: true,
			autoplaySpeed: 3000,
			fade: true,
			dots: true,
			pauseOnHover: false,
			pauseOnDotsHover: true,
			arrows : false
		});

		$('.footer-logos').slick({
			autoplay: true,
			arrows : false,
			autoplaySpeed: 1500,
			slidesToShow: 2,
			slidesToScroll: 2,
			mobileFirst: true,
			infinite: true,
			dots: true,
			focusOnSelect: false,
			responsive: [
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1
				}
			}]
		});
	},

	setupEvents: function() {
		var _this = this;

		// $('.page-offer-entry').on('click', '.button', function(event) {
		// 	event.preventDefault();

		// 	$('.page-offer-entry').removeClass('active');
		// 	$(this).parent().addClass('active');
		// });

		$('form').on('click', '.button', function(event) {
			event.preventDefault();
			$(this).submit();
		});

		$('form').on('submit', function(event) {
			event.preventDefault();

			var $this = $(this),
				action = $(this).attr('action'),
				data = $(this).serialize(),
				formId = $this.attr('id');

		    $.ajax({
		      url : action,
		      type : 'POST',
		      cache : false,
		      data : data,
		      success : function(res) {

		        res = JSON.parse(res)

		        if (res.status == true && formId == 'request') {
		        	$this.find('.error').addClass('hidden');
		        	$this.find('.success').removeClass('hidden');
		        	$this.find('.button').addClass('hidden');
		        }

		        if (res.status == false && formId == 'request') {
		        	$this.find('.error').removeClass('hidden');
		        	$this.find('.success').addClass('hidden');
		        	$this.find('.button').removeClass('hidden');
		        }
		      }
		    })
		});

		// $('.slider-nav').on('click', 'a', function(event) {
		// 	event.preventDefault();
		// 	var elementIndex = $(this).parent().index();

		// 	_this.sliderInstance.move(elementIndex);
		// });

		$('.header-nav-trigger a').click(function(event) {
			event.preventDefault();
			_this.toggleMobileNav();
		});

		$('.page-content-mask').click(function(event) {
			event.preventDefault();
			_this.toggleMobileNav();
		});

		$(window).on('resize', function(event) {
			event.preventDefault();
			_this.onResize();
		});

		$(window).on('scroll', function(event) {
			_this.onScroll();
		});
	},

	onResize: function() {
		app.W = $(window).width()
		app.H = $(window).height()
	},

	onScroll: function() {
		app.scrollTop = $(window).scrollTop()
	},

	scrollToTop: function() {
		if ($(window).scrollTop() > 0) {
			this.scrollTo({
				pos: 0, 
				speed: 0.4
			});
		}
	},

	initWOW: function() {

	},

	toggleMobileNav: function() {
		var $header = $('header'),
			$nav = $('.header-nav-trigger'),
			$pageContent = $('.page-content');

		if ($header.hasClass('active')) {
			$header.removeClass('active');
			// $pageContent.removeClass('mask');
			$header.removeClass('active');
			$nav.removeClass('active');
		} else {
			$header.addClass('active');
			// $pageContent.addClass('mask');
			$nav.addClass('active');
		}

	},

	scrollTo: function(params) {

		if (params.pos == undefined) {params.pos = 0};
		if (params.speed == undefined) {params.speed = 0.8};

		TweenLite.to( window, params.speed, {
			scrollTo: {
				y: params.pos
			},
			ease: Cubic.EaseIn
		});
	},

	showHideMobileNav: function(param) {
		var $header = $('header'),
			$nav = $('.header-mobileNav-trigger'),
			param = param || {};
			// $pageContent = $('.page-content');

		if (app.isMobileNavOpen || param.hide == true) {
			$header.removeClass('active');
			$nav.removeClass('active');
			app.isMobileNavOpen = false
		} else {
			$header.addClass('active');
			$nav.addClass('active');
			app.isMobileNavOpen = true
		}
	},

	preload: function(images) {

		var $body = $('body'),
			_this = this;

		if (images) {
			$.each(images, function(index, val) {
				$body.find('.footer').append('<img class="preloading" src=" ' + val +' ">');
			});
		}

		imagesLoaded( 'body', { background: true }, function() {
			$body.find('.preloading').each(function(index, el) {
				$(this).remove();
			});

			$body.removeClass('loading');

			if (!_this.isHome) {
				setTimeout(function() {
					var pageContentOffsetTop = $('.page-content').offset().top

					pageContentOffsetTop = pageContentOffsetTop - $('.header').height() - 20

					_this.scrollTo({
						pos: pageContentOffsetTop
					});
				}, 600);
			}

			// _this.sliderInstance = new _this.slider( $('.slider') );
			// _this.sliderInstance.init();
		});
	} // ,

	// slider: function($slider) {
	// 	var _this = this,
	// 		sliderIndex = 0,
	// 		sliderMax = $slider.find('.slider-holder .slider-box').length,
	// 		pxToMove = $slider.find('.slider-holder-outer').width(),
	// 		isMoved = false,
	// 		interval,
	// 		previousActive;

	// 	function setupEvents() {
			
	// 		var mc = new Hammer($slider.get(0));

	// 		mc.on("swipeleft", function(ev) {
	// 			moveRight();
	// 		});

	// 		mc.on("swiperight", function(ev) {
	// 			moveLeft();
	// 		});

	// 		$slider.on( "swipeleft",  function(event){
	// 			moveLeft();
	// 		});

	// 		$slider.on( "swiperight",  function(event){
	// 			moveRight();
	// 		});

	// 		$slider.on('click', '.counter-move-left', function(event) {
	// 			event.preventDefault();
	// 			moveLeft();
	// 		});

	// 		$slider.on('click', '.counter-move-right', function(event) {
	// 			event.preventDefault();
	// 			moveRight();
	// 		});

	// 		$slider.on('parentResized', function(event) {
	// 			event.preventDefault();
	// 			pxToMove = $slider.find('.slider-holder-outer').width();

	// 			setSliderBoxWidth();
	// 			move(0);
	// 		});

	// 		$(window).on('resize', function(event) {
	// 			$slider.trigger('parentResized');
	// 		});
	// 	}

	// 	function setSliderBoxWidth() {
	// 		var parentWidth = $slider.find('.slider-holder-outer').width();

	// 		$.each($slider.find('.slider-box'), function(index, val) {
	// 			 $(val).css('width', parentWidth + "px");
	// 		});
	// 	}

	// 	function move(thisIndex) {
	// 		$slider.find('.slider-holder').each(function(index, el) {
	// 			var px;

	// 			if (!isNaN(thisIndex)) {

	// 				sliderIndex = thisIndex;

	// 				$('.slider-count .counter').html(sliderIndex+1);

	// 				$('body').find('.slider-nav li').removeClass('active');
	// 				$('body').find('.slider-nav li:nth-child('+(sliderIndex+1)+')').addClass('active');

	// 				previousActive = $('body').find('.slider-holder li.active');

	// 				$('body').find('.slider-holder li').removeClass('fadeOut');
	// 				previousActive.removeClass('active').addClass('fadeOut');
	// 				$('body').find('.slider-holder li:nth-child('+(sliderIndex+1)+')').addClass('active');
	// 			};
	// 		});
			
	// 		clearSliderInterval();
	// 		startSliderInterval();
	// 	}

	// 	function moveLeft() {
	// 		if (sliderIndex > 0) {
	// 			sliderIndex--;
	// 			move(sliderIndex);
	// 		} else if (sliderIndex < 1) {
	// 			sliderIndex = sliderMax - 1;
	// 			move(sliderIndex)
	// 		}
	// 	}

	// 	function moveRight() {
	// 		if (sliderIndex < sliderMax - 1) {
	// 			sliderIndex++;
	// 			move(sliderIndex);
	// 		} else if (sliderIndex == sliderMax - 1) {
	// 			sliderIndex = 0;
	// 			move(0);
	// 		}
	// 	}

	// 	function undelegateEvents()  {
	// 		$slider.unbind('parentResized');
	// 	}

	// 	function startSliderInterval() {
	// 		interval = setInterval(function() {
	// 			moveRight();
	// 		}, 3000);
	// 	}

	// 	function clearSliderInterval() {
	// 		clearInterval(interval);
	// 	}

	// 	function init() {
	// 		setupEvents();
	// 		setSliderBoxWidth();
	// 		startSliderInterval();

	// 		setTimeout(function() {
	// 			$slider.find('.slider-holder .slider-box').first().addClass('active');
	// 		}, 50);
	// 	}

	// 	return {
	// 		init: init,
	// 		undelegateEvents: undelegateEvents,
	// 		move: move,
	// 		moveRight: moveRight
	// 	}
	// }
};

$(document).ready(function() {
	window.app.init();
});